<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      :disable-delete="true"
      :disable-filter="(isLoading || isFetching) && tableData.length === 0"
      @per-page-change="perPage = $event"
    >
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="CheckIcon" class="text-success mr-1" />
          Terima Pesanan ({{ selectedDatas.length }})
        </b-dropdown-item>
        <!-- <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item> -->
      </template>
    </TableHeader>

    <b-skeleton-table
      v-if="isLoading"
      :rows="4"
      :columns="7"
      :table-props="{ borderless: true, striped: true, small: true }"
    />

    <b-table-simple v-else responsive striped small style="zoom: 83%">
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 6%">
            <b-form-checkbox
              v-model="selectAll"
              size="md"
              @change="selectAllRows"
            />
          </b-th>
          <b-th
            v-for="(column, index) in tableColumns"
            :key="index"
            :class="[column.class]"
            :style="{ width: column.style }"
          >
            {{ column.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <template v-for="(data, index) in tableData">
          <b-tr
            :key="'main-row-' + index"
            :style="
              data.productList.length < 1
                ? 'opacity: 0.5; pointer-events: none; user-select: none; border-left: 2px; border-right: 2px; border-top: 2px dashed red; background-color: #f8d7da; cursor: not-allowed;'
                : ''
            "
          >
            <b-td>
              <b-form-checkbox
                v-model="selectedDatas"
                :value="data"
                size="md"
              />
            </b-td>
            <b-td>
              <b-img
                :src="
                  require('@/assets/images/marketplace/' +
                    data.marketplaceType +
                    '.png')
                "
                :alt="data.marketplaceName"
                style="width: 24px; height: 24px"
              />
              {{ data.marketplaceName ? data.marketplaceName : 'Toko Dummy' }}
            </b-td>
            <b-td>
              <div class="d-flex flex-column">
                <div class="my-1">
                  <b-badge variant="primary" class="mr-1">
                    {{
                      marketplaceAbbreviation(data.marketplaceType) +
                      '-' +
                      (data.orderId ? data.orderId : '-')
                    }}
                  </b-badge>
                  <b-badge class="mr-1">
                    {{ formatDateWithTime(data.createdDate) }}
                  </b-badge>
                </div>
                <div>
                  <div class="d-flex flex-column">
                    <b class="mb-1"> Daftar Produk Dibeli :</b>
                    <b-table-simple borderless>
                      <b-tbody>
                        <b-tr
                          v-for="(product, productIndex) in data.productList"
                          :key="productIndex"
                        >
                          <b-td>
                            <div class="d-flex flex-column">
                              <div class="d-flex align-items-center">
                                <b-img
                                  width="60"
                                  :src="
                                    product.image
                                      ? product.image
                                      : 'https://cdn.statically.io/avatar/' +
                                        getFirstCharOfWords(product.productName)
                                  "
                                  :alt="'product #' + productIndex"
                                  thumbnail
                                  class="mr-1"
                                />
                                <div class="flex-grow-1">
                                  <h6 class="text-primary">
                                    <b>{{ product.productName }}</b>
                                  </h6>

                                  <div class="d-flex flex-column mb-1">
                                    <!-- productId -->
                                    <small>
                                      <b> ID Produk :</b>
                                      {{
                                        product.productId
                                          ? product.productId
                                          : '-'
                                      }}
                                    </small>
                                    <!-- SKU -->
                                    <small>
                                      <b>SKU :</b>
                                      {{ product.sku ? product.sku : '-' }}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <div class="d-flex flex-column">
                      <span class="line-dashed mt-1" />
                      <small>
                        <dl class="row my-1">
                          <dt class="col-3">Nama Pembeli :</dt>
                          <dd class="col-9">
                            {{ data.recipientName ? data.recipientName : '-' }}
                          </dd>
                          <dt class="col-3">Nomor Resi :</dt>
                          <dd class="col-9">
                            {{
                              data.trackingNumber ? data.trackingNumber : '-'
                            }}
                          </dd>
                          <dt class="col-3">Kurir :</dt>
                          <dd class="col-9">{{ data.courierName }}</dd>
                        </dl>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </b-td>
            <b-td class="text-right">
              <strong>{{ formattedCurrency(data.totalPrice) }}</strong>
            </b-td>
            <b-td class="text-center">{{
              formattedNumber(data.quantity)
            }}</b-td>
          </b-tr>

          <b-tr
            :key="'productList-' + index"
            :style="
              data.productList.length < 1
                ? 'opacity: 0.5; pointer-events: none; user-select: none; border-left: 2px; border-right: 2px; background-color: #f8d7da; cursor: not-allowed;'
                : ''
            "
          >
            <b-td colspan="5">
              <div
                class="d-flex justify-content-between align-items-center flex-wrap my-1"
              >
                <h5 class="text-danger" style="font-size: 0.9rem">
                  <span
                    v-if="
                      data.marketplaceType === 'tokopedia' &&
                      pickupRequestCourrier.includes(data.courierName)
                    "
                  >
                    <font-awesome-icon
                      :icon="['fas', 'exclamation-triangle']"
                      class="mr-1"
                      style="margin-right: 0.4rem !important"
                    />
                    Pesanan ini memerlukan request pick-up! Pastikan untuk
                    mencetak AWB terlebih dahulu.
                  </span>
                </h5>
                <div class="d-flex justify-content-around mr-1">
                  <b-button
                    v-if="
                      data.marketplaceType === 'tokopedia' ||
                      data.marketplaceType === 'lazada' ||
                      data.marketplaceType === 'shopee'
                    "
                    :disabled="data.productList.length < 1"
                    variant="info"
                    size="sm"
                    class="d-flex align-items-center mr-1"
                    @click="printAWB(data.marketplaceType, data.orderId)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-alt']"
                      class="mr-1"
                    />
                    <span>Cetak AWB</span>
                  </b-button>

                  <b-button
                    v-if="
                      data.marketplaceType === 'tokopedia' &&
                      pickupRequestCourrier.includes(data.courierName)
                    "
                    :disabled="data.productList.length < 1"
                    variant="secondary"
                    size="sm"
                    class="d-flex align-items-center"
                    @click="requestPickup(data.marketplaceType, data.orderId, data._id)"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'truck-pickup']"
                      class="mr-1"
                    />
                    <span>Request Pick-up</span>
                  </b-button>

                  <b-button
                    v-if="
                      data.marketplaceType === 'tokopedia' &&
                      !pickupRequestCourrier.includes(data.courierName)
                    "
                    :disabled="data.productList.length < 1"
                    variant="success"
                    size="sm"
                    class="d-flex align-items-center"
                    @click="
                      deliveryConfirmation(data.marketplaceType, data.orderId, data.trackingNumber, data._id)
                    "
                  >
                    <font-awesome-icon
                      :icon="['fas', 'handshake-angle']"
                      class="mr-1"
                    />
                    <span>Konfirmasi Pengiriman</span>
                  </b-button>
                </div>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <EmptyTableRow v-else :colspan="tableColumns.length + 1" />
    </b-table-simple>

    <div v-if="isFetching" class="p-2">
      <b-skeleton-table
        :rows="4"
        :columns="7"
        :table-props="{ borderless: true, striped: true, small: true }"
      />
    </div>

    <b-modal
      id="modalAWB"
      size="lg"
      title="Cetak AWB"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      style="height: 800px"
    >
      <div style="overflow-x: hidden">
        <iframe
          :src="AWBUrl"
          scrolling="vertical"
          frameborder="0"
          style="width: 100%; height: 400px"
        ></iframe>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from '@axios';
import orderMixins from '../mixins/orderMixins';

export default defineComponent({
  mixins: [orderMixins],
  data() {
    return {
      orderStatus: 'ACCEPTED',
      AWBUrl: '',
      tableColumns: [
        {
          label: 'nama toko',
          class: 'bg-transparent text-center',
          style: '15%',
        },
        {
          label: 'Produk dan Data Pesanan',
          class: 'bg-transparent text-center',
          style: '50%',
        },
        {
          label: 'Total Harga',
          class: 'bg-transparent text-center',
          style: '15%',
        },
        {
          label: 'Total Pesanan',
          class: 'bg-transparent text-center',
          style: '15%',
        },
      ],
      pickupRequestCourrier: [
        'AnterAja',
        'SiCepat',
        'GrabExpress',
        'Pos Indonesia',
        'Lion Parcel',
        'GoSend',
      ],
      isLoading: false,
      // tableData: [
      //   {
      //     _id: '65cee11e66cd61b0312345',
      //     orderStatusInternal: 'ACCEPTED',
      //     user: 'budiarto',
      //     marketplaceType: 'tokopedia',
      //     marketplaceName: null,
      //     orderId: 1777249072,
      //     quantity: 1,
      //     recipientName: null,
      //     createdDate: '2024-02-16 11:14:18',
      //     courierName: 'JNE',
      //     trackingNumber: null,
      //     productList: [
      //       {
      //         productId: 9333129523,
      //         productName:
      //           "Puritan's Pride Glucosamine Chondroitin MSM 60 kap, Original USA",
      //         sku: '025077178971',
      //         quantity: 1,
      //         price: 149000,
      //       },
      //     ],
      //     totalPrice: 149000,
      //   },
      //   {
      //     _id: '65cee11e66cd34x3343435',
      //     orderStatusInternal: 'ACCEPTED',
      //     user: 'budiarto',
      //     marketplaceType: 'tokopedia',
      //     marketplaceName: null,
      //     orderId: 1777249072,
      //     quantity: 1,
      //     recipientName: null,
      //     createdDate: '2024-02-16 11:14:18',
      //     courierName: 'GrabExpress',
      //     trackingNumber: null,
      //     productList: [
      //       {
      //         productId: 9333129523,
      //         productName:
      //           "Puritan's Pride Glucosamine Chondroitin MSM 60 kap, Original USA",
      //         sku: '025077178971',
      //         quantity: 1,
      //         price: 149000,
      //       },
      //     ],
      //     totalPrice: 149000,
      //   },
      //   {
      //     _id: '65cee11e66cd61b03xxxx',
      //     orderStatusInternal: 'ACCEPTED',
      //     user: 'budiarto',
      //     marketplaceType: 'lazada',
      //     marketplaceName: null,
      //     orderId: 1777249888,
      //     quantity: 1,
      //     recipientName: null,
      //     createdDate: '2024-02-16 11:14:18',
      //     courierName: 'JNE',
      //     trackingNumber: null,
      //     productList: [
      //       {
      //         productId: 988888523,
      //         productName:
      //           "Puritan's Pride Glucosamine Chondroitin MSM 60 kap, Original USA",
      //         sku: '025077178971',
      //         quantity: 1,
      //         price: 149000,
      //       },
      //     ],
      //     totalPrice: 149000,
      //   },
      //   {
      //     _id: '65cee11e66cd34x3334322',
      //     orderStatusInternal: 'ACCEPTED',
      //     user: 'budiarto',
      //     marketplaceType: 'tokopedia',
      //     marketplaceName: null,
      //     orderId: 1777249072,
      //     quantity: 1,
      //     recipientName: null,
      //     createdDate: '2024-02-16 11:14:18',
      //     courierName: 'GrabExpress',
      //     trackingNumber: null,
      //     productList: [
      //       {
      //         productId: 9333122523,
      //         productName: 'Sebuah Produk Dummy',
      //         sku: '025077178971',
      //         quantity: 1,
      //         price: 25000,
      //       },
      //     ],
      //     totalPrice: 25000,
      //   },
      // ],
    };
  },
  methods: {
    printAWB(marketplace = 'tokopedia', orderId = null) {
      if (orderId === null) {
        this.$swal({
          title: 'Pilih pesanan terlebih dahulu',
          icon: 'warning',
        });
      } else {
        // get accessToken from localstorage
        const token = localStorage.getItem('accessToken');

        const swalConfig = {
          text: `Apakah Anda yakin ingin mencetak AWB [${orderId}]?`,
          icon: 'question',
          confirmButtonColor: '#2196f3',
          showCancelButton: true,
          cancelButtonText: 'Batal Cetak',
          confirmButtonText: 'Lanjutkan',
          allowOutsideClick: false,
          showLoaderOnConfirm: true,
        };

        // AWB for Tokopedia
        if (marketplace === 'tokopedia') {
          swalConfig.title = 'Cetak AWB Tokopedia';
          swalConfig.input = 'checkbox';
          swalConfig.inputValue = 1;
          swalConfig.inputPlaceholder =
            'Tandai Pesanan Sebagai "Sudah Dicetak"';

          this.$swal(swalConfig).then(result => {
            if (result.isConfirmed) {
              // get AWB URL
              this.AWBUrl = `${
                process.env.VUE_APP_API_URL
              }/tokopedia/order/print-label?orderId=${orderId}&ns=false&token=${token}&printed=${
                result.value === 1 ? '1' : '0'
              }`;

              this.$bvModal.show('modalAWB');
            }
          });
        }

        // AWB for Lazada
        if (marketplace === 'lazada') {
          swalConfig.title = 'Cetak AWB Lazada';

          this.$swal(swalConfig).then(result => {
            if (result.isConfirmed) {
              // get AWB URL
              // TODO: API_NOT_READY - get AWB URL for Lazada
              this.AWBUrl = `${
                process.env.VUE_APP_API_URL
              }/lazada/order/print-label?orderId=${orderId}&ns=false&token=${token}&printed=${
                result.value === 1 ? '1' : '0'
              }`;

              this.$bvModal.show('modalAWB');
            }
          });
        }
      }
    },

    requestPickup(marketplace = 'tokopedia', orderId = null, id = null) {
      this.$swal({
        title: 'Request Pick-up',
        text: `Apakah Anda yakin ingin request pick-up untuk pesanan [${orderId}]?`,
        icon: 'question',
        confirmButtonColor: '#2196f3',
        showCancelButton: true,
        cancelButtonText: 'Batal Request',
        confirmButtonText: 'Lanjutkan',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          // TODO: TEST - request pick-up
          const response = await axios.post(`${marketplace}/order/request-pickup`, { orderId });
          this.isLoading = true;

          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            this.tableData = this.tableData.filter(item => item._id !== id);
            this.isLoading = false;
            // set timeout to reload page after 3 seconds
            // setTimeout(() => {
            //   this.window.location.reload();
            // }, 3000);
          }
        }
      });
    },

    deliveryConfirmation(marketplace = 'tokopedia', orderId = null, shippingRef = null, id = null) {
      this.$swal({
        title: 'Konfirmasi Pengiriman',
        text: `Apakah Anda yakin ingin konfirmasi pengiriman untuk pesanan [${orderId}]?`,
        icon: 'question',
        confirmButtonColor: '#2196f3',
        showCancelButton: true,
        cancelButtonText: 'Batal Konfirmasi',
        confirmButtonText: 'Lanjutkan',
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          // TODO: TEST - delivery confirmation
          const response = await axios.post(`${marketplace}/order/confirm-shipping`, { orderId, shippingRef });
          this.isLoading = true;

          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            this.tableData = this.tableData.filter(item => item._id !== id);
            this.isLoading = false;
            // set timeout to reload page after 3 seconds
            // setTimeout(() => {
            //   this.window.location.reload();
            // }, 3000);
          }
        }
      });
    },
  },
});
</script>

<style lang="scss">
.line-dashed {
  border-bottom: 1px dashed #e0e0e0;
}
</style>
